<template>
  <div>
    <div
      class="tooltip-shadow flex w-auto px-2.5 py-4 bg-white items-center rounded-md font-roboto"
      :class="[
        `tooltip-${direction}`,
        rightBarTooltip
          ? 'min-w-1 sm:min-w-1.25 xl:min-w-1.5 gap-1 sm:gap-1.5 xl:gap-2.5 rtl:justify-end flip-tooltip-right-bar'
          : 'min-w-2 gap-1.5 flip-tooltip',
        customTooltip,
        minWidth,
        tooltip,
      ]"
    >
      <div class="rounded-full justify-center mb-auto items-center" :class="childClass">
        <div class="w-7.5 h-7.5 rounded-full">
          <img v-if="url !== null" :src="`${url}`" class="h-full w-full rounded-full" />
          <div
            v-else-if="url === null"
            class="h-full w-full rounded-full bg-gray-300 flex justify-center items-center text-xxs text-gray-700"
          >
            {{ initials(nameInitials) }}
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-1 flip-tooltip ltr:order-2 rtl:order-1">
        <div
          class="text-xs lg:text-sm font-medium text-text-color capitalize"
          :class="[
            rightBarTooltip
              ? 'ltr:text-center xl:ltr:text-left rtl:text-center xl:rtl:text-right'
              : 'ltr:text-left rtl:text-right',
          ]"
        >
          {{ title }}
        </div>
        <div :class="[textExtraSmall ? 'text-xxs' : 'text-xxs lg:text-xs']">
          <div class="text-primary-grey-light mb-4">
            {{ text }}
          </div>
          <div v-for="(detail, index) in readOnlyListOfDetail" :key="index" class="-ml-8 pb-1">
            <span v-for="(detailValue, detailKey) in detail" :key="detailKey">
              <span class="font-medium pr-2">{{ detailKey }}:</span>
              <span class="text-menu">
                {{ detailValue }}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import generalUtil from '@src/mixins/general-mixins.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'

export default {
  components: {},
  mixins: [generalUtil],

  props: {
    title: { type: [String, Number], default: '' },
    text: { type: String, default: '' },
    hideIcon: { type: Boolean, default: false },
    minWidth: { type: String, default: '' },
    textExtraSmall: { type: Boolean, default: false },
    direction: { type: String, default: 'right' },
    rightBarTooltip: { type: Boolean, default: false },
    customTooltip: { type: String, default: '' },
    url: { type: String, default: null },
    nameInitials: { type: String, default: GENERAL_CONSTANTS.NO_RECORD_FOUND },
    childClass: { type: String, default: 'flex' },

    readOnlyListOfDetail: {
      type: Array,
      default: () => [],
    },
    tooltip: { type: String, default: 'tooltip-top' },
  },
}
</script>

<style lang="scss" scoped>
.tooltip-top {
  &::before {
    position: absolute;
    top: -7px;
    width: 13px;
    height: 13px;
    content: '';
    background: var(--bg-white);
    border-top: 1px solid #0003;
    border-left: 1px solid #0003;
    opacity: 1;
    transform: rotate(45deg);
  }
  &-right {
    &::before {
      right: 28px;
      @media (max-width: 450px) {
        right: 95px;
      }
    }
  }
  &-left {
    &::before {
      left: 17px;
    }
  }
}
.tooltip-bottom {
  &::before {
    position: absolute;
    bottom: -7px;
    width: 13px;
    height: 13px;
    content: '';
    background: var(--bg-white);
    border-top: 1px solid #0003;
    border-left: 1px solid #0003;
    opacity: 1;
    transform: rotate(45deg);
  }
  &-right {
    &::before {
      right: 28px;
      @media (max-width: 450px) {
        right: 95px;
      }
    }
  }
  &-left {
    &::before {
      left: 17px;
    }
  }
}
.tooltip-shadow {
  box-shadow: 1px 1px 15px #0003;
  &::before {
    margin-left: 12px;
    border: none;
  }
}
.tooltip-border {
  border: none;
}
.tooltip-width {
  min-width: 132px;
}
.tooltip-padding {
  padding: 2px;
  padding-left: 10px;
}
.tooltip-margin {
  margin-top: 40px;
  margin-left: -10px;
}

body[dir='ltr'] {
  .flip-tooltip-right-bar {
    -ms-filter: fliph; /* IE */
    filter: fliph; /* IE */
    -moz-transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1);
  }
}

body[dir='rtl'] {
  .flip-tooltip,
  .flip-tooltip-right-bar {
    -ms-filter: fliph; /* IE */
    filter: fliph; /* IE */
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .tooltip {
    &::before {
      top: -8px;
    }
  }
}

@media (max-width: 769px) {
  body[dir='ltr'] {
    .tooltip {
      &-right {
        &::before {
          right: 16px;
        }
      }
    }
  }

  body[dir='rtl'] {
    .tooltip {
      &-right {
        &::before {
          top: -7px;
          right: 16px;
        }
      }
    }
  }
}

/* IPAD TO LOWER UPTO 810 PX WIDTH */

/* UPTO 600 PX WIDTH */
@media screen and (max-width: 600px) {
  body[dir='ltr'] {
    .tooltip {
      .leftbartooltip {
        margin-left: 80px !important;
      }
    }
  }
  body[dir='rtl'] {
    .tooltip {
      .leftbartooltip {
        margin-right: 82px !important;
      }
      &-left {
        &::before {
          left: 13px !important;
        }
      }
    }
  }
}

/* EXCEPTIONAL CASE */
@media only screen and (min-width: 450px) and (max-width: 600px) {
  .tooltip {
    &::before {
      position: relative !important;
      top: -32px !important;
      left: 5px !important;
    }
  }
}
</style>

<style lang="scss" module>
.toolTip {
  top: 70px;
  right: 195px;
  z-index: 5;
}
</style>
