<template>
  <div
    class="badge flex relative text-sm h-8 border border-primary-green rounded-full"
    @mouseover="showSingleItem()"
    @mouseleave="hideSingleItem()"
  >
    <div class="items-center flex px-1.8 gap-1 overflow-auto">
      <div class="rounded-full flex justify-center items-center">
        <div v-if="image" class="rounded-full w-5.5 h-5.5">
          <img v-if="url" :src="url" class="h-full w-full rounded-full" />
          <div
            v-else
            class="h-full w-full rounded-full flex justify-center items-center text-xxs bg-primary-purple-50 text-primary-purple-600"
          >
            {{ initials(nameInitials) }}
          </div>
        </div>
      </div>
      <div class="truncate ml-1 pr-1 capitalize overflow-auto">
        {{ title.replaceAll('_', ' ') }}
      </div>
    </div>
    <div
      v-if="singleSelectedItem"
      class="absolute z-10 ltr:-left-2.5 rtl:-right-4"
      :class="dropdownTopBottom"
    >
      <Tooltip
        class="w-min sm:w-auto"
        :title="title"
        :text="description"
        :url="url"
        direction="left"
        :name-initials="nameInitials"
        :read-only-list-of-detail="readOnlyListOfDetail"
        :tooltip="tooltip"
      />
    </div>
  </div>
</template>

<script>
import Tooltip from '@src/components/Usertooltip.vue'
import generalUtil from '@src/mixins/general-mixins.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'

export default {
  name: 'UiMultiSelectBox',
  components: {
    Tooltip,
  },
  mixins: [generalUtil],

  props: {
    url: { type: String, default: null },
    nameInitials: { type: String, default: GENERAL_CONSTANTS.NO_RECORD_FOUND },
    dropdownTopBottom: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    title: { type: [String, Number], default: '' },
    description: { type: [String, Number], default: '' },
    image: {
      type: Boolean,
      default: false,
    },
    readOnlyListOfDetail: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      singleSelectedItem: false,
      GENERAL_CONSTANTS,
    }
  },

  methods: {
    /**
     * Show Single Item
     * @description Show single chip detailed tooltip
     */
    showSingleItem() {
      this.singleSelectedItem = true
    },
    /**
     * Hide Single Item
     * @description Hide single chip detailed tooltip
     */
    hideSingleItem() {
      this.singleSelectedItem = false
    },
  },
}
</script>

<style lang="scss" scoped>
ul {
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: start;
  list-style: none;
}

.badge .tooltip-text {
  &::before {
    position: absolute;
    top: -7px;
    width: 13px;
    height: 13px;
    content: '';
    transform: rotate(45deg);
  }

  position: absolute;
  top: 35px;
  left: -8px;
  z-index: 1;
  min-height: 40px;
  padding: 5px;
  margin-left: 5px;
  overflow-y: hidden;
  color: rgb(12, 12, 12);
  text-align: left;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  box-shadow: 0 0 3px 0.1px;
  transition: opacity 0.3s;
}
</style>
