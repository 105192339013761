<template>
  <div class="flex">
    <template v-if="options.length">
      <div
        v-for="(item, index) in optionsArray.slice(0, requiredItems)"
        :key="index"
        class="flex flex-row cursor-pointer"
        :class="badgeMargin"
      >
        <Chips
          :class="chipswidth(optionsArray)"
          class="bg-white"
          :url="item && item.image ? item.image : null"
          :title="getLabel(item) || GENERAL_CONSTANTS.NOT_APPLICABLE"
          :name-initials="`${item.first_name} ${item.last_name}`"
          :read-only-list-of-detail="getDetail(item)"
          :image="image"
          :dropdown-top-bottom="dropdownTopBottom"
          :tooltip="tooltip"
        />
      </div>
    </template>
    <span v-else class="font-roboto font-normal text-text-color text-sm">
      {{ GENERAL_CONSTANTS.NOT_APPLICABLE }}
    </span>
    <div
      v-if="optionsArray.length > 3"
      class="badge rtl:-mr-10 ltr:-ml-10 cursor-pointer relative justify-center flex items-center bg-white border border-primary-green rounded-full text-sm w-9 h-9"
    >
      <p class="items-center p-1.5" @click.stop="showAllOptions()">+{{ updateBadge }}</p>
      <div
        v-if="allOptions"
        class="selected-options-container zindex bg-white text-text-color absolute w-56 rounded-md"
      >
        <ul class="text-base px-2">
          <li
            v-for="(item, idx) in optionsArray"
            :key="idx"
            class="optionsBorder border-border-line py-3"
          >
            <div class="flex mb-2">
              <div v-if="item.image" class="w-7 h-7">
                <img :src="item.image" class="h-full w-full rounded-full" />
              </div>
              <span
                v-else
                class="h-7 w-7 rounded-full flex justify-center items-center text-sm bg-primary-purple-50 text-primary-purple-600"
              >
                {{ initials(`${item.first_name} ${item.last_name}`) }}
              </span>
              <div class="text-sm truncate w-4/5 pl-2 flex flex-col justify-center">
                <div class="text-xs lg:text-sm font-medium text-text-color capitalize pt-1">
                  {{ getLabel(item) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                </div>
              </div>
            </div>
            <div
              v-for="(detail, index) in getDetail(item)"
              :key="index"
              class="text-xxs lg:text-xs pl-1 text-text-color pb-1"
            >
              <span v-for="(detailValue, detailKey) in detail" :key="detailKey">
                <span class="font-medium pr-2">{{ detailKey }}:</span>
                <span class="text-menu">
                  {{ detailValue }}
                </span>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import generalMixin from '@src/mixins/general-mixins.js'
import Chips from '@src/components/UiElements/UserChips.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import { transformTextToCapitalize } from '@utils/generalUtil'
export default {
  components: { Chips },
  mixins: [generalMixin],

  props: {
    profile: {
      type: Boolean,
      default: false,
    },
    badgeMargin: {
      type: String,
      default: '',
    },
    dropdownTopBottom: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: 'tooltip-top',
    },
    options: {
      type: [Array, Object],
      default: () => [],
    },
    nestedLabel: {
      type: Array,
      default: () => [],
    },
    nestedDetail: {
      type: Array,
      default: () => [],
    },
    requiredItems: { type: Number, default: 3 },
    label: { type: [String, Number], default: 'title' },
    reduce: { type: String, default: '' },
    url: { type: String, default: '' },
    isObjectConcated: {
      type: Boolean,
      /* To avoid ripple effect */
      // eslint-disable-next-line vue/no-boolean-default
      default: true,
    },
    image: {
      type: Boolean,
      default: false,
    },
    multipleItemsListToGetDetail: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      GENERAL_CONSTANTS,
      allOptions: false,
      optionsArray: [],
    }
  },
  computed: {
    updateBadge() {
      return this.optionsArray.length - 3
    },
  },
  watch: {
    options: {
      immediate: true,
      handler() {
        if (Array.isArray(this.options)) {
          this.optionsArray = JSON.parse(JSON.stringify(this.options))
        } else if (this.isObjectConcated) {
          /* OLD LOGIC INCREASE BAGES */
          this.optionsArray.push(this.options)
        } else {
          /* NEW LOGIC RESIST BADGE LENGTH */
          this.optionsArray = [this.options]
        }
      },
    },
  },
  methods: {
    chipswidth(optionsArray) {
      return optionsArray.length === 1 ? 'w-44' : optionsArray.length === 2 ? 'w-25.5' : 'w-19'
    },
    /**
     * Show All Options
     * @description Toggle all items via tooltip on clicking Badge
     */
    showAllOptions() {
      this.allOptions = !this.allOptions
    },
    /**
     * Get Detail
     * @param {object | *} option - Object or any other datatype
     * @return {string | *} - Returns string in case of object else returns parameter as it is.
     * @description Gets option as param and returns mentioned value of key that provided in item
     */
    getDetail(option) {
      let returnValue = option
      if (typeof option === 'object') {
        /* GET FINAL OPTION OBJECT */
        if (this.nestedDetail.length) {
          this.nestedDetail.forEach((key) => {
            option = option[key]
          })
        }
        /* FINAL ARRAY OF OBJECTS IN KEY VALUE PAIR TO DISPLAY */
        returnValue = this.multipleItemsListToGetDetail.map((key) => {
          let capitalizeKey = transformTextToCapitalize(key)
          const detail = {}
          detail[capitalizeKey] = option[key] ? option[key] : GENERAL_CONSTANTS.NOT_APPLICABLE
          return detail
        })
      } else if (typeof option === 'string') {
        return
      }
      return returnValue
    },
    /**
     * Get Label
     * @param {object | *} option - Object or any other datatype
     * @return {string | *} - Returns string in case of object else returns parameter as it is.
     * @description Gets option as param and returns mentioned value of key that provided in item
     */
    getLabel(option) {
      if (typeof option === 'object') {
        if (this.nestedLabel.length) {
          let nested = option

          this.nestedLabel.forEach((op) => {
            if (nested) nested = nested[op]
          })

          return nested
        } else return option[this.label]
      }
      return option
    },
  },
}
</script>

<style lang="scss" scoped>
.optionsBorder {
  border-bottom: 0.5px solid rgb(219, 216, 216);
}
.optionsBorder:last-child {
  border-bottom: none;
}

ul {
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: start;
  list-style: none;
}
.selected-options-container {
  top: 52px;
  right: -5px;
  z-index: 20;
  min-height: 40px;
  box-shadow: 1px 1px 15px #0003;
  transition: opacity 0.3s;
  &::before {
    position: absolute;
    top: -7px;
    right: 20px;
    width: 13px;
    height: 13px;
    content: '';
    background: var(--bg-white);
    border-top: 1px solid #0003;
    border-left: 1px solid #0003;
    opacity: 1;
    transform: rotate(45deg);
  }
}
</style>
